import React, { useState } from 'react'
import Card from '../../components/card';
import { Link } from 'react-router-dom';
import logo from '../../assets/logo.svg'
import Agility from "../../assets/vocational/agility.svg"
import SchSport from "../../assets/vocational/schSport.svg"
import SchWork from "../../assets/vocational/schWork.svg"
import ServiceWork from "../../assets/vocational/serviceWork.svg"
import WaterSports from "../../assets/vocational/waterSports.svg"
import Background from "../../assets/backgroud.svg"

import { useDispatch } from 'react-redux';
import { updateSpecialization, updateVocational } from '../../store/modules/auth/action';
export default function StepThree({ children }) {
    const dispatch = useDispatch();
    const [breed, setbreed] = useState({
        small: false,
        medium: false,
        large: false,
    });

    const handleVocationalChange = (e) => {
        if (breed === e) {
            setbreed('')
        }
        else { setbreed(e); }
    }

    const handleSaveVocational = () => {
        // dispatch(updateVocational(breed));
    }
    const handleCheckboxChange = (option) => {
        console.log('', option)
        setbreed((prevValues) => ({
            ...prevValues,
            [option]: !prevValues[option],
        }));
        console.log("features",)
    };
    return (
        <div className='bg-black flex justify-center items-center h-screen py-12'>
                        <img src={Background} className='w-full h-full absolute'/>

            <div className='lg:w-8/12 w-full bg-white shadow-md rounded-xl p-12 h-full relative'>
                <div className='flex items-center space-x-3'>
                <div className='w-16 h-16rounded-full'>
                        <img src={logo} />
                    </div>
                    <p className='font-bold text-xl uppercase'>
                        Tamers
                    </p>
                </div>
                <div className='py-8'>
                    <div className='w-full bg-gray-100 rounded-full overflow-hidden'>
                        <div className='w-3/12 bg-black py-1 '>

                        </div>
                    </div>
                </div>
                <h1 className='text-xl font-bold'>
                    What best describes your needs?

                </h1>
                <p className='text-gray-400'>
                    Pick as many as you like. We'll make sure to set you up for the right services.
                </p>
                <div className='pt-5 grid md:grid-cols-2 grid-cols-1 gap-5'>
                    <Card title="Dog Training" icon={Agility} value={'small'} selected={breed?.small ? 'small' : ''} handleChange={() => handleCheckboxChange('small')} handleClick={() => handleCheckboxChange('small')} type={'checkbox'} />
                    <Card title="Pet Data Tracking" icon={WaterSports} value={'medium'} selected={breed?.medium ? 'medium' : ''} handleChange={() => handleCheckboxChange('medium')} handleClick={() => handleCheckboxChange('medium')} type={'checkbox'} />
                </div>
                <div className='absolute bottom-10 right-10 flex justify-end items-center space-x-3'>

                    <Link to="/owner/step-three">
                        <button className='bg-black text-white rounded-lg px-3 py-1' onClick={handleSaveVocational}>
                            Next
                        </button>
                    </Link>
                </div>
            </div>
        </div>
    )
}