import React, { useEffect, useState } from 'react'
import Card from '../../components/card';
import { Link } from 'react-router-dom';
import logo from '../../assets/logo.svg'
import Back from "../../assets/back.svg"
import Scent from "../../assets/specializations/scent.svg"
import Medical from "../../assets/specializations/medical.svg"
import Mobility from "../../assets/specializations/mobility.svg"
import Seeing from "../../assets/specializations/seeing.svg"
import Therapy from "../../assets/specializations/therapy.svg"
import Agility from "../../assets/requestTypes/agility.svg"
import Puppy from "../../assets/requestTypes/puppy.svg"
import Behavior from "../../assets/requestTypes/behavior.svg"
import Vocational from "../../assets/requestTypes/vocational.svg"
import Obedience from "../../assets/requestTypes/obedience.svg"
import Background from "../../assets/backgroud.svg"

import { useDispatch } from 'react-redux';
import { updateSpecialization, getServiceSpecialization } from '../../store/modules/auth/action';
import { updateConsultation, getRequestTypes, register } from '../../store/modules/owner/actions';
import { useSelector } from 'react-redux';
export default function StepFour({ children }) {
    const dispatch = useDispatch();
    const [specialization, setSpecialization] = useState([]);
    const [specializations, setSpecializations] = useState([])
    const state = useSelector(state => state?.owner)
    const handleSpecializationChange = (e) => {
        if (specialization.includes(e)) {
            // If it does, remove it
            setSpecialization(specialization.filter(item => item !== e));
        } else {
            // If it doesn't, add it
            setSpecialization([...specialization, e]);
        }
        console.log(specialization)
    }

    const handleSaveSpecialization = () => {
        dispatch(updateSpecialization(specialization));
    }
    useEffect(() => {
        dispatch(getRequestTypes())
    }, [])
    useEffect(() => {
        console.log('state', state)
        setSpecializations(state?.requestTypes)
    }, [state])
    return (
        <div className='bg-black flex justify-center items-center h-screen py-12'>
            <img src={Background} className='w-full h-full absolute' />

            <div className='lg:w-8/12 w-full bg-white shadow-md rounded-xl p-12 h-full relative'>
                <div className='flex items-center space-x-3'>
                    <div className='w-16 h-16rounded-full'>
                        <img src={logo} />
                    </div>
                    <p className='font-bold text-xl uppercase'>
                        Tamers
                    </p>
                </div>
                <div className='py-8'>
                    <div className='w-full bg-gray-100 rounded-full overflow-hidden'>
                        <div className='w-9/12 bg-black py-1 '>

                        </div>
                    </div>
                </div>
                <h1 className='text-xl font-bold'>
                    Tell us about your business. What are your preferred training types?This question is required.*
                </h1>
                <p className='text-gray-400'>
                    Pick as many as you'd like. You can always change later.


                </p>
                <div className='pt-5 grid grid-cols-2 gap-5'>

                    {
                        specializations?.map((val) => (
                            <Card title={val?.name} icon={val?.name === "Puppy" ? Puppy : val?.name === "Agility" ? Agility : val?.name === "Vocational" ? Vocational : val?.name === "Behavourial" ? Behavior : val?.name === "Obedience" ? Obedience : Seeing} value={val?.name} selected={specialization?.includes(val) ? val?.name : ""} handleClick={() => handleSpecializationChange(val)} type={'checkbox'} imageClass={'w-8'} />
                        ))
                    }
                </div>
                <div className='absolute bottom-10 left-10'>
                    <Link to='/step-three'>
                        <div className='flex items-center space-x-3'>
                            <img src={Back} alt='back' className='w-6' />
                            <p className='text-sm cursor-pointer'>
                                Back
                            </p>
                        </div>
                    </Link>
                </div>
                <div className='absolute bottom-10 right-10 flex justify-end items-center space-x-3'>
                    <Link to="/schdule">
                        <p className='text-sm cursor-pointer'>
                            Skip all
                        </p>
                    </Link>
                    <Link to="/step-four">
                        <p className='text-sm cursor-pointer'>
                            Skip
                        </p>
                    </Link>
                    <Link to="/step-four">
                        <button className='bg-black text-white rounded-lg px-3 py-1' onClick={handleSaveSpecialization}>
                            Next
                        </button>

                    </Link>
                </div>
            </div>
        </div>
    )
}