import React from 'react';
import Back from "../../assets/back.svg"
import logo from '../../assets/logo.svg'
import Background from "../../assets/backgroud.svg"

import { Link } from 'react-router-dom'
import { useNavigate } from "react-router-dom";
const TimezoneSelector = () => {
    const navigate = useNavigate();
    return (
        <div className='bg-black flex justify-center items-center h-screen py-12'>
                        <img src={Background} className='w-full h-full absolute' />

            <div className='lg:w-8/12 w-full bg-white shadow-md rounded-xl md:p-12 p-4 h-full relative'>
                <div className='flex items-center space-x-3'>
                    <div className='w-16 h-16rounded-full'>
                        <img src={logo} />
                    </div>
                    <p className='font-bold text-xl uppercase'>
                        Tamers
                    </p>
                </div>
                <div className='py-8'>
                    <div className='w-full bg-gray-100 rounded-full overflow-hidden'>
                        <div className='w-11/12 bg-black py-1 '>

                        </div>
                    </div>
                </div>
                <section className="body current" style={{ left: '0px' }}>
                    <label className='text-xl font-bold pt-3'>Select one or several slots. You can change this later on.</label>
                    <div className="container">

                        <div className="grid grid-cols-3 items-end">
                            <div>
                                <p className="col-span-5 md:text-normal text-sm py-3">
                                    Morning Before noon
                                </p>
                                <p className="col-span-5 md:text-normal text-sm pb-3">
                                    Afternoon 12-5pm
                                </p>
                                <p className="col-span-5 md:text-normal text-sm pb-3">
                                    Evening After 5pm
                                </p>
                            </div>
                            <div className=" mt-5 col-span-2">
                                <div className="grid grid-cols-7 gap-1">
                                    <div className="col md:text-normal text-xs">Mon</div>
                                    <div className="col md:text-normal text-xs">Tue</div>
                                    <div className="col md:text-normal text-xs">Wed</div>
                                    <div className="col md:text-normal text-xs">Thr</div>
                                    <div className="col md:text-normal text-xs">Fri</div>
                                    <div className="col md:text-normal text-xs">Sat</div>
                                    <div className="col md:text-normal text-xs">Sun</div>
                                </div>
                                <div className="grid grid-cols-7 gap-1 py-3">

                                    <div className="col-span-1">
                                        <div className="flex items-center">
                                            <input id="MonBeforeNoon" type="checkbox" name="MonBeforeNoon" className="mr-2 w-4 cursor-pointer h-4 p-1" />
                                            <label htmlFor="MonBeforeNoon"></label>
                                        </div>
                                    </div>
                                    <div className="col-span-1">
                                        <div className="flex items-center">
                                            <input id="TueBeforeNoon" type="checkbox" name="TueBeforeNoon" className="mr-2 w-4 cursor-pointer h-4 p-1" />
                                            <label htmlFor="TueBeforeNoon"></label>
                                        </div>
                                    </div>
                                    <div className="col-span-1">
                                        <div className="flex items-center">
                                            <input id="TueBeforeNoon" type="checkbox" name="TueBeforeNoon" className="mr-2 w-4 cursor-pointer h-4 p-1" />
                                            <label htmlFor="TueBeforeNoon"></label>
                                        </div>
                                    </div>
                                    <div className="col-span-1">
                                        <div className="flex items-center">
                                            <input id="TueBeforeNoon" type="checkbox" name="TueBeforeNoon" className="mr-2 w-4 cursor-pointer h-4 p-1" />
                                            <label htmlFor="TueBeforeNoon"></label>
                                        </div>
                                    </div>
                                    <div className="col-span-1">
                                        <div className="flex items-center">
                                            <input id="TueBeforeNoon" type="checkbox" name="TueBeforeNoon" className="mr-2 w-4 cursor-pointer h-4 p-1" />
                                            <label htmlFor="TueBeforeNoon"></label>
                                        </div>
                                    </div>
                                    <div className="col-span-1">
                                        <div className="flex items-center">
                                            <input id="TueBeforeNoon" type="checkbox" name="TueBeforeNoon" className="mr-2 w-4 cursor-pointer h-4 p-1" />
                                            <label htmlFor="TueBeforeNoon"></label>
                                        </div>
                                    </div>
                                    <div className="col-span-1">
                                        <div className="flex items-center">
                                            <input id="TueBeforeNoon" type="checkbox" name="TueBeforeNoon" className="mr-2 w-4 cursor-pointer h-4 p-1" />
                                            <label htmlFor="TueBeforeNoon"></label>
                                        </div>
                                    </div>
                                    {/* ... Repeat for other days ... */}
                                </div>
                                <div className="grid grid-cols-7 gap-1 py-3">

                                    <div className="col-span-1">
                                        <div className="flex items-center">
                                            <input id="MonBeforeNoon" type="checkbox" name="MonBeforeNoon" className="mr-2 w-4 cursor-pointer h-4 p-1" />
                                            <label htmlFor="MonBeforeNoon"></label>
                                        </div>
                                    </div>
                                    <div className="col-span-1">
                                        <div className="flex items-center">
                                            <input id="TueBeforeNoon" type="checkbox" name="TueBeforeNoon" className="mr-2 w-4 cursor-pointer h-4 p-1" />
                                            <label htmlFor="TueBeforeNoon"></label>
                                        </div>
                                    </div>
                                    <div className="col-span-1">
                                        <div className="flex items-center">
                                            <input id="TueBeforeNoon" type="checkbox" name="TueBeforeNoon" className="mr-2 w-4 cursor-pointer h-4 p-1" />
                                            <label htmlFor="TueBeforeNoon"></label>
                                        </div>
                                    </div>
                                    <div className="col-span-1">
                                        <div className="flex items-center">
                                            <input id="TueBeforeNoon" type="checkbox" name="TueBeforeNoon" className="mr-2 w-4 cursor-pointer h-4 p-1" />
                                            <label htmlFor="TueBeforeNoon"></label>
                                        </div>
                                    </div>
                                    <div className="col-span-1">
                                        <div className="flex items-center">
                                            <input id="TueBeforeNoon" type="checkbox" name="TueBeforeNoon" className="mr-2 w-4 cursor-pointer h-4 p-1" />
                                            <label htmlFor="TueBeforeNoon"></label>
                                        </div>
                                    </div>
                                    <div className="col-span-1">
                                        <div className="flex items-center">
                                            <input id="TueBeforeNoon" type="checkbox" name="TueBeforeNoon" className="mr-2 w-4 cursor-pointer h-4 p-1" />
                                            <label htmlFor="TueBeforeNoon"></label>
                                        </div>
                                    </div>
                                    <div className="col-span-1">
                                        <div className="flex items-center">
                                            <input id="TueBeforeNoon" type="checkbox" name="TueBeforeNoon" className="mr-2 w-4 cursor-pointer h-4 p-1" />
                                            <label htmlFor="TueBeforeNoon"></label>
                                        </div>
                                    </div>
                                    {/* ... Repeat for other days ... */}
                                </div>
                                <div className="grid grid-cols-7 gap-1 py-3">

                                    <div className="col-span-1">
                                        <div className="flex items-center">
                                            <input id="MonBeforeNoon" type="checkbox" name="MonBeforeNoon" className="mr-2 w-4 cursor-pointer h-4 p-1" />
                                            <label htmlFor="MonBeforeNoon"></label>
                                        </div>
                                    </div>
                                    <div className="col-span-1">
                                        <div className="flex items-center">
                                            <input id="TueBeforeNoon" type="checkbox" name="TueBeforeNoon" className="mr-2 w-4 cursor-pointer h-4 p-1" />
                                            <label htmlFor="TueBeforeNoon"></label>
                                        </div>
                                    </div>
                                    <div className="col-span-1">
                                        <div className="flex items-center">
                                            <input id="TueBeforeNoon" type="checkbox" name="TueBeforeNoon" className="mr-2 w-4 cursor-pointer h-4 p-1" />
                                            <label htmlFor="TueBeforeNoon"></label>
                                        </div>
                                    </div>
                                    <div className="col-span-1">
                                        <div className="flex items-center">
                                            <input id="TueBeforeNoon" type="checkbox" name="TueBeforeNoon" className="mr-2 w-4 cursor-pointer h-4 p-1" />
                                            <label htmlFor="TueBeforeNoon"></label>
                                        </div>
                                    </div>
                                    <div className="col-span-1">
                                        <div className="flex items-center">
                                            <input id="TueBeforeNoon" type="checkbox" name="TueBeforeNoon" className="mr-2 w-4 cursor-pointer h-4 p-1" />
                                            <label htmlFor="TueBeforeNoon"></label>
                                        </div>
                                    </div>
                                    <div className="col-span-1">
                                        <div className="flex items-center">
                                            <input id="TueBeforeNoon" type="checkbox" name="TueBeforeNoon" className="mr-2 w-4 cursor-pointer h-4 p-1" />
                                            <label htmlFor="TueBeforeNoon"></label>
                                        </div>
                                    </div>
                                    <div className="col-span-1">
                                        <div className="flex items-center">
                                            <input id="TueBeforeNoon" type="checkbox" name="TueBeforeNoon" className="mr-2 w-4 cursor-pointer h-4 p-1" />
                                            <label htmlFor="TueBeforeNoon"></label>
                                        </div>
                                    </div>
                                    {/* ... Repeat for other days ... */}
                                </div>
                            </div>
                            {/* ... Other columns ... */}
                        </div>

                    </div>
                </section>
                <div className='flex items-center justify-between pt-5 absolute bottom-10 w-11/12'>
                    <div className=''>
                        <div className='flex items-center space-x-3' onClick={() => navigate(-1)}>
                            <img src={Back} alt='back' className='w-6' />
                            <p className='text-sm cursor-pointer'>
                                Back
                            </p>
                        </div>
                    </div>
                    <div className=' flex justify-end items-center space-x-3'>
                        <button className='bg-black text-white rounded-lg px-3 py-1'  >
                            Complete
                        </button>
                    </div>
                </div>
            </div>
        </div>

    );
};

export default TimezoneSelector;
