
import React, { useEffect, useState } from 'react'
import Card from '../../components/card';
import { Link } from 'react-router-dom';
import Back from "../../assets/back.svg"
import { useForm, Controller } from 'react-hook-form';
import { useNavigate } from "react-router-dom";
import { useDispatch } from 'react-redux';
import Background from "../../assets/backgroud.svg"

import { updateUserInfo, getCountries } from '../../store/modules/auth/action';
import { useSelector } from 'react-redux';
import logo from '../../assets/logo.svg'
export default function Signup({ children }) {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const state = useSelector(state => state)
    const countries = useSelector(state => state.auth.countries);
    const [userData, setUserData] = useState({
        firstName: "",
        lastName: "",
        country: "",
        email: '',
        password: '',
        phoneNumber: ''
    })
    useEffect(() => {
        dispatch(updateUserInfo(userData))
    }, [userData])
    useEffect(() => {
        dispatch(getCountries())
    }, [])
    const handleSaveLevel = () => {
        const data = {
            "countryId": state?.auth?.country,
            "phoneNumber": state?.auth?.userInfo?.phoneNumber,
            "password": state?.auth?.userInfo?.password,
            "name": state?.auth?.userInfo?.firstName,
            "surname": state?.auth?.userInfo?.lastName,
            "businessEmailAddress": state?.auth?.userInfo?.email,
            "hasEcommerce": state?.auth?.features === 'ecommerce' ? true : false,
            "useInClientFinder": state?.auth?.features === 'client finder' ? true : false,
            "services": state?.auth?.specialization,
            "vocationalSpecializations": [
                state?.auth?.vocational
            ],
            "serviceSpecializations": [
                state?.auth?.specialization
            ],
            "difficultyLevel": state?.auth?.level === 'easy' ? 0 : state?.auth?.level === 'moderate' ? 1 : 2
        }
        console.log('data', data, state)
        dispatch(register(data))
    }
    const { register, handleSubmit, control, setValue, formState: { errors } } = useForm();


    const onSubmit = (data) => {
        console.log(data)
        dispatch(updateUserInfo(data));
        navigate('/sign-up')
    };

    const handleCountryChange = (value) => {
        setValue('country', value);
    };
    return (
        <div className="bg-black flex justify-center items-center h-screen py-12">
            <img src={Background} className='w-full h-full absolute' />

            <form
                onSubmit={handleSubmit(onSubmit)}
                className="md:w-4/12 w-full bg-white shadow-md rounded-xl p-12 relative"
            >
                {/* ... other JSX code ... */}
                <div className='flex items-center space-x-3'>
                    <div className='w-16 h-16rounded-full'>
                        <img src={logo} />
                    </div>
                    <p className='font-bold text-xl uppercase'>
                        Tamers
                    </p>
                </div>

        <div className='py-8'>
          <div className='w-full bg-gray-100 rounded-full overflow-hidden'>
            <div className='w-6 bg-black py-1 '>

            </div>
          </div>
        </div>
                <h1 className='text-xl font-bold pt-3'>
                    Create a Tamers account

                </h1>
                <div className="pt-5">
                    <div>
                        <p className="pb-1">First name</p>
                        <input
                            {...register("firstName", { required: true, maxLength: 20 })}
                            className="w-full border border-gray-400 py-1 px-2 rounded-md focus:outline-none"
                        />
                        {errors.firstName && <p className='text-xs text-red-500'>First Name is required</p>}
                        <p className="pb-1">Last name</p>
                        <input
                            {...register("lastName", { required: true, maxLength: 20 })}
                            className="w-full border border-gray-400 py-1 px-2 rounded-md focus:outline-none"
                        />
                        {errors.lastName && <p className='text-xs text-red-500'>Last Name is required</p>}
                        {/* ... other input fields ... */}
                        <div>
                            <p className="pb-1">Country/region</p>
                            <select
                                className="w-full border border-gray-400 py-1 px-2 rounded-md focus:outline-none"
                                onChange={(e) => {
                                    handleCountryChange(e.target.value);
                                }}
                                {...register("country", { required: true })}
                            >
                                {countries?.map((value) => (
                                    <option key={value?.id} value={value?.id}>
                                        {value?.name}
                                    </option>
                                ))}
                                <option>Other</option>
                            </select>
                            {errors.country && <p className='text-xs text-red-500'>Country is required</p>}
                        </div>
                        <div>
                            {/* <p className="pb-1">City</p> */}
                            {/* <select
                                className="w-full border border-gray-400 py-1 px-2 rounded-md focus:outline-none"
                                {...register("city", { required: true })}
                            >
                                {countries?.map((value) => (
                                    <option key={value?.id} value={value?.id}>
                                        {value?.name}
                                    </option>
                                ))}
                                <option>Other</option>
                            </select> */}
                            {/* {errors.city && <p className='text-xs text-red-500'>City is required</p>} */}
                            <p className="pb-1">City</p>
                            <input
                                {...register("city", { required: true, maxLength: 20 })}
                                className="w-full border border-gray-400 py-1 px-2 rounded-md focus:outline-none"
                            />
                            {errors.city && <p className='text-xs text-red-500'>City is required</p>}
                            {/* ... other input fields ... */}
                        </div>
                        <div>
                            <p className="pb-1">State</p>
                            <input
                                {...register("state", { required: true, maxLength: 20 })}
                                className="w-full border border-gray-400 py-1 px-2 rounded-md focus:outline-none"
                            />
                            {errors.state && <p className='text-xs text-red-500'>State is required</p>}
                        </div>

                    </div>
                </div>
                <div className="flex items-center justify-between pt-5">
                    <Link to='/'>
                        <div className='flex items-center space-x-3'>
                            <img src={Back} alt='back' className='w-6' />
                            <p className='text-sm cursor-pointer'>
                                Back
                            </p>
                        </div>
                    </Link>
                    <div className=" flex justify-end items-center space-x-3">
                        <button
                            type="submit"
                            className="bg-black text-white rounded-lg px-3 py-1"
                        >
                            Next
                        </button>
                    </div>
                </div>
            </form>
        </div>
    )
}
