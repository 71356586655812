import React, { useState } from 'react'
import Card from '../components/card';
import { Link } from 'react-router-dom';
import Agility from "../assets/vocational/agility.svg"
import WaterSports from "../assets/vocational/waterSports.svg"
import logo from '../assets/logo.svg'
import Background from "../assets/backgroud.svg"
export default function StoreCreate({ children }) {
  const [userType, setUserType] = useState('dog owner');

  const handleUserType = (e) => {
    setUserType(e.target.value);
  }
  return (
    <div className='flex justify-center items-center h-screen py-12 relative' >
      <img src={Background} className='w-full h-full absolute'/>
      <div className='md:w-8/12 w-full bg-white shadow-md rounded-xl md:p-12 p-5 h-full relative'>
        <div className='flex items-center space-x-3'>
        <div className='w-16 h-16rounded-full'>
                        <img src={logo} />
                    </div>
          <p className='font-bold text-xl uppercase'>
            Tamers
          </p>
        </div>
        <div className='py-8'>
          <div className='w-full bg-gray-100 rounded-full overflow-hidden'>
            <div className='w-6 bg-black py-1 '>

            </div>
          </div>
        </div>
        <h1 className='text-xl font-bold'>
          What best describes your needs?

        </h1>
        <p className='text-gray-400'>
         Pick as many as you like, We'll make sure to set you up for the right services
        </p>

        <div className='pt-5 grid md:grid-cols-2 grid-cols-1 gap-5'>
          <Card title="I’m a dog owner" icon={Agility} value={'dog owner'} selected={userType} handleChange={handleUserType} handleClick={()=>setUserType('dog owner')}/>
          <Card title="I’m a dog trainer" icon={WaterSports} value={'dog trainer'} selected={userType}  handleChange={handleUserType} handleClick={()=>setUserType('dog trainer')}/>
        </div>
        <div className='absolute bottom-10 right-10 flex justify-end items-center space-x-3'>
        
          <Link to={userType === 'dog owner' ? "/owner" : "/add-info"}>
            <button className='bg-black text-white rounded-lg px-3 py-1' >
              Next
            </button>
          </Link>
        </div>
      </div>
    </div>
  )
}
