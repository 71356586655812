import React from 'react'

export default function Card({ title, icon, handleChange, value, selected, handleClick, type, imageClass }) {
    return (
        <div className='flex item-center justify-between py-1 hover:bg-gray-50 border border-gray-100 hover:border-black px-3 rounded-lg cursor-pointer' onClick={handleClick}>
            <div className='flex space-x-3  py-4 '>
                <input type={type ? type : 'radio'} checked={value === selected} value={value} onChange={handleChange} />
                <p className='text-sm font-light'>

                    {title}
                </p>
            </div>
            <img src={icon} className={imageClass ? imageClass : 'w-12'} />
        </div>
    )
}
