import React, { useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import Uplaod from "../assets/upload.png"
const DropzoneComponent = () => {
  const [selectedFiles, setSelectedFiles] = useState([]);

  const onDrop = useCallback(acceptedFiles => {
    // Do something with the files
    console.log(acceptedFiles);
    setSelectedFiles(acceptedFiles);
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: 'image/*', // Specify the file types you want to accept
  });

  return (
    <div>
      <div {...getRootProps()} style={dropzoneStyle} className='flex flex-col items-center'>
        <input {...getInputProps()} />
        <img src={Uplaod} alt='some' className='w-32'/>
        {isDragActive ? (
          <p>Drop the files here...</p>
        ) : (
            <div>
          <p><span className='font-medium'>Choose file</span> or <span className='font-medium'>Drag here</span> </p>
          <p>Size limit : 10 MB</p>
          
          </div>
        )}
      </div>
      {selectedFiles.length > 0 && (
        <div>
          <h4>Selected Files:</h4>
          <ul>
            {selectedFiles.map(file => (
              <li key={file.name}>{file.name}</li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

const dropzoneStyle = {
  width: '100%',
  minHeight: '100px',
  border: '2px dashed #cccccc',
  borderRadius: '4px',
  cursor: 'pointer',
  padding: '20px',
  textAlign: 'center',
};

export default DropzoneComponent;
