
import React, { useEffect, useState } from 'react'
import Card from '../../components/card';
import { Link } from 'react-router-dom';
import Back from "../../assets/back.svg"
import { useForm, Controller } from 'react-hook-form';
import logo from '../../assets/logo.svg'
import { useNavigate } from "react-router-dom";
import { useDispatch } from 'react-redux';
import Background from "../../assets/backgroud.svg"

import { updateUserInfo, getCountries, updateUserBusinessInfo } from '../../store/modules/auth/action';
import { useSelector } from 'react-redux';
export default function Signup({ children }) {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { register, handleSubmit, control, watch, setValue, formState: { errors } } = useForm();


    const onSubmit = (data) => {
        dispatch(updateUserBusinessInfo(data))
        navigate('/license')
    };
    const handleCountryChange = (value) => {
        setValue('country', value);
    };
    return (
        <div className="bg-black flex justify-center items-center h-screen py-12">
            <img src={Background} className='w-full h-full absolute' />

            <form
                onSubmit={handleSubmit(onSubmit)}
                className="md:w-4/12 w-full bg-white shadow-md rounded-xl p-12 relative"
            >
                {/* ... other JSX code ... */}
                <div className='flex items-center space-x-3'>
                    <div className='w-16 h-16rounded-full'>
                        <img src={logo} />
                    </div>
                    <p className='font-bold text-xl uppercase'>
                        Tamers
                    </p>
                </div>
                <h1 className='text-xl font-bold pt-3'>
                    Create a Tamers account

                </h1>
                <div className="pt-5">
                    <div>
                        {/* <p className="pb-1">First name</p>
                        <input
                            {...register("firstName", { required: true, maxLength: 20 })}
                            className="w-full border border-gray-400 py-1 px-2 rounded-md focus:outline-none"
                        />
                        {errors.firstName && <p className='text-xs text-red-500'>First Name is required</p>}
                        <p className="pb-1">Last name</p>
                        <input
                            {...register("lastName", { required: true, maxLength: 20 })}
                            className="w-full border border-gray-400 py-1 px-2 rounded-md focus:outline-none"
                        /> */}
                        {errors.lastName && <p className='text-xs text-red-500'>Last Name is required</p>}
                        {/* ... other input fields ... */}
                        {/* <div>
                            <p className="pb-1">Country/region</p>
                            <select
                                className="w-full border border-gray-400 py-1 px-2 rounded-md focus:outline-none"
                                onChange={(e) => {
                                    handleCountryChange(e.target.value);
                                }}
                                {...register("country", { required: true })}
                            >
                                {countries?.map((value) => (
                                    <option key={value?.id} value={value?.id}>
                                        {value?.name}
                                    </option>
                                ))}
                                <option>Other</option>
                            </select>
                            {errors.country && <p className='text-xs text-red-500'>Country is required</p>}
                        </div> */}
                        {/* <div>
                            <p className="pb-1">City</p>
                            <select
                                className="w-full border border-gray-400 py-1 px-2 rounded-md focus:outline-none"
                                {...register("city", { required: true })}
                            >
                                {countries?.map((value) => (
                                    <option key={value?.id} value={value?.id}>
                                        {value?.name}
                                    </option>
                                ))}
                                <option>Other</option>
                            </select>
                            {errors.city && <p className='text-xs text-red-500'>City is required</p>}
                        </div> */}
                        <div>
                            <p className="pb-1">Business email address</p>
                            <input
                                type="email"
                                {...register("email",
                                    {
                                        required: true,
                                        pattern: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                                    })}
                                className="w-full border border-gray-400 py-1 px-2 rounded-md focus:outline-none"
                            />
                            {errors.email && <p className='text-xs text-red-500'>Email is required</p>}
                        </div>
                        <div>
                            <p className="pb-1">Password</p>
                            <input
                                type="password"
                                {...register("password", {
                                    required: true
                                })}
                                className="w-full border border-gray-400 py-1 px-2 rounded-md focus:outline-none"
                            />
                            {errors.password && <p className='text-xs text-red-500'>Password is required</p>}
                            <p className="pb-1">Confirm Password</p>
                            <input
                                type="password"
                                {...register("confirm_password", {
                                    required: true,
                                    validate: (val) => {
                                        if (watch('password') != val) {
                                            return "Your passwords do no match";
                                        }
                                    },
                                })}
                                className="w-full border border-gray-400 py-1 px-2 rounded-md focus:outline-none"

                            />
                            {errors.confirm_password && <p className='text-xs text-red-500'>{`${errors?.confirm_password?.message}`}</p>}

                        </div>
                    </div>
                </div>
                <div className="flex items-center justify-end pt-5">
                    <div className=" flex justify-end items-center space-x-3">
                        <button
                            type="submit"
                            className="bg-black text-white rounded-lg px-3 py-1"
                        >
                            Signup
                        </button>
                    </div>
                </div>
            </form>
        </div>
    )
}
