import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import logo from '../../assets/logo.svg'
import Card from '../../components/card';
import Back from "../../assets/back.svg"
import Yes from "../../assets/yes.svg"
import No from "../../assets/no.svg"
import Background from "../../assets/backgroud.svg"
import DropzoneComponent from '../../components/dropzone';
export default function AddLicense() {
    const [selected, setSelected] = useState('Yes')
    const [vocational, setvocational] = useState(false);
    return (
        <div className='bg-black flex justify-center items-center h-screen md:py-12 py-0 '>
            <img src={Background} className='w-full h-full absolute' />

            <div className='lg:w-8/12 w-full bg-white shadow-md md:rounded-xl rounded-none md:p-12 p-4  relative h-[700px] overflow-auto'>
                <div className='flex items-center space-x-3'>
                    <div className='w-16 h-16rounded-full'>
                        <img src={logo} />
                    </div>
                    <p className='font-bold text-xl uppercase'>
                        Tamers
                    </p>
                </div>

                <div className='py-8'>
                    <div className='w-full bg-gray-100 rounded-full overflow-hidden'>
                        <div className='w-4/12 bg-black py-1 '>

                        </div>
                    </div>
                </div>
                <h1 className='text-xl font-bold pt-3'>
                    Are you a licensed trainer? If so please upload your license certificate.

                </h1>
                <p className='text-gray-400 text-sm pb-4'>
                    Your license will be used to allow you access to the client finder solution.   </p>
                <div className=''>
                    <DropzoneComponent />
                </div>
                <div className='flex items-center justify-between pt-5 absolute bottom-10 w-11/12'>
                    <div className=''>
                        <Link to='/license'>
                            <div className='flex items-center space-x-3'>
                                <img src={Back} alt='back' className='w-6' />
                                <p className='text-sm cursor-pointer'>
                                    Back
                                </p>
                            </div>
                        </Link>
                    </div>
                    <div className=' flex justify-end items-center space-x-3'>
                        <Link to="/schdule">
                            <p className='text-sm cursor-pointer'>
                                Skip all
                            </p>
                        </Link>

                        <Link to="/step-one">
                            <p className='text-sm cursor-pointer'>
                                Skip
                            </p>

                        </Link>
                        <Link to="/step-one">
                            <button className='bg-black text-white rounded-lg px-3 py-1'  >
                                Next
                            </button>
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    )
}
