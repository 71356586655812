import React, { useState } from 'react'
import Card from '../../components/card';
import { Link } from 'react-router-dom';
import Back from "../../assets/back.svg"
import logo from '../../assets/logo.svg'
import Agility from "../../assets/vocational/agility.svg"
import SchSport from "../../assets/vocational/schSport.svg"
import SchWork from "../../assets/vocational/schWork.svg"
import ServiceWork from "../../assets/vocational/serviceWork.svg"
import WaterSports from "../../assets/vocational/waterSports.svg"
import Background from "../../assets/backgroud.svg"

import { useNavigate } from "react-router-dom";
import { useDispatch } from 'react-redux';
import { updateSpecialization, updateVocational } from '../../store/modules/auth/action';
export default function StoreCreate({ children }) {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [breed, setbreed] = useState({
        small: false,
        medium: false,
        large: false,
    });


    const handleSaveVocational = () => {
        let data = []
        data = [...data, breed?.small ? 'small' : '']
        data = [...data, breed?.medium ? 'medium' : '']
        data = [...data, breed?.large ? 'large' : '']
        dispatch(updateVocational(data));
        navigate('/preferd-training-types')
    }
    const handleCheckboxChange = (option) => {
        console.log('', option)
        setbreed((prevValues) => ({
            ...prevValues,
            [option]: !prevValues[option],
        }));
        console.log("features",)
    };
    return (
        <div className='bg-black flex justify-center items-center h-screen py-12'>
            <img src={Background} className='w-full h-full absolute' />

            <div className='lg:w-8/12 w-full bg-white shadow-md rounded-xl p-12 h-full relative'>
                <div className='flex items-center space-x-3'>
                    <div className='w-16 h-16rounded-full'>
                        <img src={logo} />
                    </div>
                    <p className='font-bold text-xl uppercase'>
                        Tamers
                    </p>
                </div>
                <div className='py-8'>
                    <div className='w-full bg-gray-100 rounded-full overflow-hidden'>
                        <div className='w-8/12 bg-black py-1 '>

                        </div>
                    </div>
                </div>
                <h1 className='text-xl font-bold'>
                    What are your vocational specializations?

                </h1>
                <p className='text-gray-400'>
                    We’ll help get you clients matching your preference.
                </p>
                <div className='pt-5 grid md:grid-cols-2 grid-cols-1 gap-5'>
                    <Card title="Small dogs" icon={Agility} value={'small'} selected={breed?.small ? 'small' : ''} handleChange={() => handleCheckboxChange('small')} handleClick={() => handleCheckboxChange('small')} type={'checkbox'} />
                    <Card title="Medium dogs" icon={WaterSports} value={'medium'} selected={breed?.medium ? 'medium' : ''} handleChange={() => handleCheckboxChange('medium')} handleClick={() => handleCheckboxChange('medium')} type={'checkbox'} />
                    <Card title="Large dogs" icon={SchSport} value={'large'} selected={breed?.large ? 'large' : ''} handleChange={() => handleCheckboxChange('large')} handleClick={() => handleCheckboxChange('large')} type={'checkbox'} />
                </div>
                <div className='absolute bottom-10 right-10 w-11/12 flex items-center justify-between'>
                    <Link to='/step-two'>
                        <div className='flex items-center space-x-3'>
                            <img src={Back} alt='back' className='w-6' />
                            <p className='text-sm cursor-pointer'>
                                Back
                            </p>
                        </div>
                    </Link>
                    <div className='flex justify-end items-center space-x-3'>
                        <Link to="/schdule">
                            <p className='text-sm cursor-pointer'>
                                Skip all
                            </p>
                        </Link>

                        <Link to='/preferd-training-types'>
                            <p className='text-sm cursor-pointer'>
                                Skip
                            </p>

                        </Link>
                        <button className='bg-black text-white rounded-lg px-3 py-1' onClick={handleSaveVocational}>
                            Next
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}