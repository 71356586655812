import { combineReducers } from 'redux';
import authReducer from '../modules/auth/reducer'; // Import the reducer from the counter module
import ownerReducer from '../modules/owner/reducer';
const rootReducer = combineReducers({
  // You can add more module-specific reducers here
  auth: authReducer,
  owner:ownerReducer // Assign the counter reducer to a key in the root state
  // Add other reducers from different modules as needed
});

export default rootReducer;