import * as React from "react";
import * as ReactDOM from "react-dom/client";
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";

import store from "./store";

import { Provider } from 'react-redux';
import "./index.css";
import routes from "./routes"
const router = createBrowserRouter(routes);
ReactDOM.createRoot(document.getElementById("root")).render(
  <Provider store={store}>
    <RouterProvider router={router} />
  </Provider>
);