// src/modules/auth/actions.js
import axios from '../../../api';

export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';
export const REGISTER_REQUEST = 'REGISTER_REQUEST';
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const REGISTER_FAILURE = 'REGISTER_FAILURE';
export const REQUEST_TYPE_REQUEST = 'REQUEST_TYPE_REQUEST';
export const REQUEST_TYPE_SUCCESS = 'REQUEST_TYPE_SUCCESS';
export const REQUEST_TYPE_FAILURE = 'REQUEST_TYPE_FAILURE';
export const BREED_TYPE_REQUEST = 'REQUEST_TYPE_REQUEST';
export const BREED_TYPE_SUCCESS = 'REQUEST_TYPE_SUCCESS';
export const BREED_TYPE_FAILURE = 'REQUEST_TYPE_FAILURE';
export const UPDATE_INFO = 'UPDATE_INFO'

const loginRequest = () => ({
  type: LOGIN_REQUEST,
});

const loginSuccess = (user) => ({
  type: LOGIN_SUCCESS,
  payload: user,
});

const loginFailure = (error) => ({
  type: LOGIN_FAILURE,
  payload: error,
});

const registerRequest = () => ({
  type: REGISTER_REQUEST,
});

const registerSuccess = (user) => ({
  type: REGISTER_SUCCESS,
  payload: user,
});

const registerFailure = (error) => ({
  type: REGISTER_FAILURE,
  payload: error,
});

const requestTypeRequest = () => ({
  type: 'REQUEST_TYPE_REQUEST',
});

const requestTypeSuccess = (types) => ({
  type: 'REQUEST_TYPE_SUCCESS',
  payload: types,
});

const requestTypeFailure = (error) => ({
  type: 'REQUEST_TYPE_FAILURE',
  payload: error,
});

const breedTypeRequest = () => ({
  type: 'BREED_TYPE_REQUEST',
});

const breedTypeSuccess = (breeds) => ({
  type: 'BREED_TYPE_SUCCESS',
  payload: breeds,
});

const breedTypeFailure = (error) => ({
  type: 'BREED_TYPE_FAILURE',
  payload: error,
});

export const updateOwnerInfo = (value) => ({
  type: 'UPDATE_OWNERINFO',
  payload: value,
});
export const updateDogInfo = (value) => ({
  type: 'UPDATE_DOGINFO',
  payload: value,
});
export const updateConsultation = (value) => ({
  type: 'UPDATE_CONSULTATION',
  payload: value,
});


export const login = (credentials) => {
  return async (dispatch) => {
    dispatch(loginRequest());
    try {
      // Make an API call to authenticate the user
      const response = await axios.post('/api/login', credentials);
      dispatch(loginSuccess(response.data));
    } catch (error) {
      dispatch(loginFailure(error.message));
    }
  };
};



export const register = (userData) => {
  return async (dispatch) => {
    dispatch(registerRequest());
    try {
      // Make an API call to register the user
      const response = await axios.post('services/app/Customers/Register', userData);
      dispatch(registerSuccess(response.data));
    } catch (error) {
      dispatch(registerFailure(error.message));
    }
  };
};

export const getRequestTypes = () => {
  return async (dispatch) => {
    dispatch(requestTypeRequest());
    try {
      // Make an API call to register the user
      const response = await axios.get('services/app/TrainingType/GetAll');
      dispatch(requestTypeSuccess(response?.data?.result?.items));
    } catch (error) {
      dispatch(requestTypeFailure(error.message));
    }
  };
};

export const getBreeds = () => {
  return async (dispatch) => {
    dispatch(breedTypeRequest());
    try {
      // Make an API call to register the user
      const response = await axios.get('services/app/Breed/GetAll');
    
      dispatch(breedTypeSuccess(response?.data?.result?.items));
    } catch (error) {
      dispatch(breedTypeFailure(error.message));
    }
  };
};




