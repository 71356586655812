// src/modules/auth/reducer.js
import {
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  REGISTER_REQUEST,
  REGISTER_SUCCESS,
  REGISTER_FAILURE,
} from './action';

const initialState = {
  user: null,
  businessInfo:{},
  hourlyRate:'',
  vocational: "",
  specialization: "",
  features: "",
  level: "",
  countries: [],
  country: '',
  loading: false,
  userInfo: null,
  serviceSpecializations: [],
  error: null,

};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_REQUEST:
    case REGISTER_REQUEST:
      return { ...state, loading: true, error: null };

    case LOGIN_SUCCESS:
    case REGISTER_SUCCESS:
      return { ...state, user: action.payload, loading: false, error: null };

    case LOGIN_FAILURE:
    case REGISTER_FAILURE:
      return { ...state, user: null, loading: false, error: action.payload };
    case 'COUNTRIES_REQUEST':
      return { ...state, loading: true, error: null };

    case 'COUNTRIES_SUCCESS':
      return { ...state, countries: action.payload, loading: false, error: null };

    case 'COUNTRIES_FAILURE':
      return { ...state, user: null, loading: false, error: action.payload };
    
    
    case 'SERVICE_SPECIALIZATION_REQUEST':
      return { ...state, loading: true, error: null };

    case 'SERVICE_SPECIALIZATION_SUCCESS':
      return { ...state, serviceSpecializations: action.payload, loading: false, error: null };

    case 'SERVICE_SPECIALIZATION_FAILURE':
      return { ...state, user: null, loading: false, error: action.payload };

    
    case 'UPDATE_VOCATIONAL':
      return { ...state, vocational: action.payload };
    case 'UPDATE_SPECIALIZATION':
      return { ...state, specialization: action.payload };
    case 'UPDATE_FEATURES':
      return { ...state, features: action.payload };
    case 'UPDATE_LEVEL':
      return { ...state, level: action.payload };
    case 'UPDATE_COUNTRY':
      return { ...state, country: action.payload };
    case 'UPDATE_USERINFO':
      return { ...state, userInfo: action.payload };
    case 'UPDATE_USERBUSINESSINFO':
      return { ...state, businessInfo: action.payload };
    case 'UPDATE_HOURLYRATE':
      return { ...state, hourlyRate: action.payload };
    default:
      return state;
  }
};

export default authReducer;
