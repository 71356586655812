import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import Card from '../../components/card';
import Back from "../../assets/back.svg"
import Yes from "../../assets/yes.svg"
import No from "../../assets/no.svg"
export default function HouseCall() {
    const [selected, setSelected] = useState('Yes')
    const [vocational, setvocational] = useState({
        yes: false,
        no: false,
    });
    const handlevocationalChange = (option) => {
        console.log('', option)
        setvocational((prevValues) => ({
            ...prevValues,
            [option]: !prevValues[option],
        }));
        console.log("features",)
    };
    return (
        <div className='bg-black flex justify-center items-center h-screen md:py-12 py-0 '>
            <div className='lg:w-4/12 md:w-1/2 w-full bg-white shadow-md md:rounded-xl rounded-none md:p-12 p-4  relative h-[700px] overflow-auto'>
                <div className='flex items-center space-x-3'>
                    <div className='w-16 h-16 bg-gray-100 rounded-full'>

                    </div>
                    <p className='font-bold text-xl uppercase'>
                        Tamers
                    </p>
                </div>
                <h1 className='text-xl font-bold pt-3'>
                    How do you prefer to conduct training , Would you take on house calls?

                </h1>
                <p className='text-gray-400 text-sm pb-4'>
                    Trainers have the options to either take on house calls from the client finder or to set up a designed training location.
                </p>
                <div className='space-y-3'>
                <Card title="Yes to House Call" icon={Yes} value={'yes'} selected={vocational?.agility ? 'yes' : ''} handleClick={() => handlevocationalChange('yes')} type={'checkbox'} />
                    <Card title="No to House call" icon={No} value={'no'} selected={vocational?.water ? 'no' : ''} handleClick={() => handlevocationalChange('no')} type={'checkbox'} imageClass={'w-8'}/>
               
                </div>
                <div className='flex items-center justify-between pt-5 absolute bottom-10 w-10/12'>
                    <div className=''>
                        <Link to='/step-one'>
                            <div className='flex items-center space-x-3'>
                                <img src={Back} alt='back' className='w-6' />
                                <p className='text-sm cursor-pointer'>
                                    Back
                                </p>
                            </div>
                        </Link>
                    </div>
                    <div className=' flex justify-end items-center space-x-3'>
                        <Link to="/schdule">
                            <p className='text-sm cursor-pointer'>
                                Skip all
                            </p>
                        </Link>
                        <p className='text-sm cursor-pointer'>
                            Skip
                        </p>
                        <Link to="/step-three">
                            <button className='bg-black text-white rounded-lg px-3 py-1'  >
                                Next
                            </button>
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    )
}
