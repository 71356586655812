import React, { useState } from 'react'
import Card from '../../components/card';
import { Link } from 'react-router-dom';
import logo from '../../assets/logo.svg'
import Back from "../../assets/back.svg"
import Ease from "../../assets/difficulties/ease.svg"
import Moderate from "../../assets/difficulties/moderate.svg"
import Hard from "../../assets/difficulties/hard.svg"
import Background from "../../assets/backgroud.svg"

import { useDispatch } from 'react-redux';
import { useNavigate } from "react-router-dom";

import { updateLevel } from '../../store/modules/auth/action';
export default function StepFour({ children }) {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [level, setLevel] = useState({
        easy: false,
        moderate: false,
        hard: false
    });

    const handleLevelChange = (e) => {
        if (level === e) {
            setLevel('')
        }
        else { setLevel(e); }
    }
    const handleCheckboxChange = (option) => {
        console.log('', option)
        setLevel((prevValues) => ({
            ...prevValues,
            [option]: !prevValues[option],
        }));
        console.log("features",)
    };
    const handleSaveLevel = () => {
        let data = []
        data = [...data, level?.easy ? 'easy' : '']
        data = [...data, level?.moderate ? 'moderate' : '']
        data = [...data, level?.hard ? 'hard' : '']
        console.log(data)
        dispatch(updateLevel(data));
        navigate('/step-five')
        // dispatch(updateLevel(level));
    }
    return (
        <div className='bg-black flex justify-center items-center h-screen py-12'>
            <img src={Background} className='w-full h-full absolute' />

            <div className='lg:w-8/12 w-full bg-white shadow-md rounded-xl p-12 h-full relative'>
                <div className='flex items-center space-x-3'>
                    <div className='w-16 h-16rounded-full'>
                        <img src={logo} />
                    </div>
                    <p className='font-bold text-xl uppercase'>
                        Tamers
                    </p>
                </div>
                <div className='py-8'>
                    <div className='w-full bg-gray-100 rounded-full overflow-hidden'>
                        <div className='w-10/12 bg-black py-1 '>

                        </div>
                    </div>
                </div>
                <h1 className='text-xl font-bold'>
                    What case levels do you prefer to take on?

                </h1>
                <p className='text-gray-400'>
                    Pick what case difficulties you are comfortable working with. We’ll help get you clients matching your preference.
                </p>

                <div className='pt-5 grid md:grid-cols-2 grid-cols-1 gap-5'>
                    <Card title={'Easy'} icon={Ease} value={'easy'} selected={level?.easy ? 'easy' : ''} handleClick={() => handleCheckboxChange('easy')} type={'checkbox'} />
                    <Card title={'Moderate'} icon={Moderate} value={'moderate'} selected={level?.moderate ? 'moderate' : ''} handleClick={() => handleCheckboxChange('moderate')} type={'checkbox'} />
                    <Card title={'Hard'} icon={Hard} value={'hard'} selected={level?.hard ? 'hard' : ''} handleClick={() => handleCheckboxChange('hard')} type={'checkbox'} />
                </div>
                <div className='absolute bottom-10 left-10'>
                    <Link to='/preferd-training-types'>
                        <div className='flex items-center space-x-3'>
                            <img src={Back} alt='back' className='w-6' />
                            <p className='text-sm cursor-pointer'>
                                Back
                            </p>
                        </div>
                    </Link>
                </div>
                <div className='absolute bottom-10 right-10 flex justify-end items-center space-x-3'>
                    <Link to="/schdule">
                        <p className='text-sm cursor-pointer'>
                            Skip all
                        </p>
                    </Link>

                    <Link to="/step-five">
                        <p className='text-sm cursor-pointer'>
                            Skip
                        </p>

                    </Link>
                    <button className='bg-black text-white rounded-lg px-3 py-1' onClick={handleSaveLevel}>
                        Next
                    </button>
                </div>
            </div>
        </div>
    )
}
