import React, { useState } from 'react'
import Card from '../../components/card';
import { Link } from 'react-router-dom';
import Back from "../../assets/back.svg"
import logo from '../../assets/logo.svg'
import Agility from "../../assets/vocational/agility.svg"
import SchSport from "../../assets/vocational/schSport.svg"
import SchWork from "../../assets/vocational/schWork.svg"
import ServiceWork from "../../assets/vocational/serviceWork.svg"
import WaterSports from "../../assets/vocational/waterSports.svg"
import Background from "../../assets/backgroud.svg"

import { useDispatch } from 'react-redux';
import { updateSpecialization } from '../../store/modules/auth/action';
export default function StepFive({ children }) {
    const dispatch = useDispatch();
    const [vocational, setvocational] = useState({
        agility: false,
        water: false,
        schutzhundSport: false,
        schutzhundWork: false,
        serviceWork: false
    });

    const handlevocationalChange = (option) => {
        console.log('', option)
        setvocational((prevValues) => ({
            ...prevValues,
            [option]: !prevValues[option],
        }));
        console.log("features",)
    };
    const handleSavevocational = () => {
        // dispatch(updatevocational(specialization));
    }
    return (
        <div className='bg-black flex justify-center items-center h-screen py-12'>
            <img src={Background} className='w-full h-full absolute' />

            <div className='lg:w-8/12 w-full bg-white shadow-md rounded-xl p-12 h-full overflow-scroll relative'>
                <div className='flex items-center space-x-3'>
                    <div className='w-16 h-16rounded-full'>
                        <img src={logo} />
                    </div>
                    <p className='font-bold text-xl uppercase'>
                        Tamers
                    </p>
                </div>
                <div className='py-8'>
                    <div className='w-full bg-gray-100 rounded-full overflow-hidden'>
                        <div className='w-11/12 bg-black py-1 '>

                        </div>
                    </div>
                </div>
                <h1 className='text-xl font-bold'>
                    What are your vocational specializations?

                </h1>
                <p className='text-gray-400'>
                    We’ll help get you clients matching your preference.
                </p>
                <div className='pt-5 grid md:grid-cols-2 grid-cols-1 gap-5'>
                    <Card title="Agillity sports" icon={Agility} value={'agility sports'} selected={vocational?.agility ? 'agility sports' : ''} handleClick={() => handlevocationalChange('agility')} type={'checkbox'} />
                    <Card title="Water sports" icon={WaterSports} value={'water sports'} selected={vocational?.water ? 'water sports' : ''} handleClick={() => handlevocationalChange('water')} type={'checkbox'} />
                    <Card title="Schutzhund sport" icon={SchSport} value={'schutzhund sport'} selected={vocational?.schutzhundSport ? 'schutzhund sport' : ''} handleClick={() => handlevocationalChange('schutzhundSport')} type={'checkbox'} />
                    <Card title="Schutzhund work" icon={SchWork} value={'schutzhund work'} selected={vocational?.schutzhundWork ? 'schutzhund work' : ''} handleClick={() => handlevocationalChange('schutzhundWork')} type={'checkbox'} />
                    <Card title="Service work" icon={ServiceWork} value={'service work'} selected={vocational?.serviceWork ? 'service work' : ''} handleClick={() => handlevocationalChange('serviceWork')} type={'checkbox'} />
                </div>
                <div className='flex items-center justify-between pt-4'>
                    <div className='md:absolute static bottom-10 left-10'>
                        <Link to='/step-four'>
                            <div className='flex items-center space-x-3'>
                                <img src={Back} alt='back' className='w-6' />
                                <p className='text-sm cursor-pointer'>
                                    Back
                                </p>
                            </div>
                        </Link>
                    </div>
                    <div className='md:absolute static bottom-10 right-10 flex justify-end items-center space-x-3'>
                        <Link to="/schdule">
                            <p className='text-sm cursor-pointer'>
                                Skip all
                            </p>
                        </Link>
                        <p className='text-sm cursor-pointer'>
                            Skip
                        </p>
                        <Link to={vocational?.serviceWork ? "/step-six" : '/sign-up'}>
                            <button className='bg-black text-white rounded-lg px-3 py-1' onClick={handleSavevocational}>
                                Next
                            </button>
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    )
}