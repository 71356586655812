import React, { useEffect, useState } from 'react'
import Card from '../../components/card';
import { Link } from 'react-router-dom';
import Back from "../../assets/back.svg"
import logo from '../../assets/logo.svg'
import Multiselect from "multiselect-react-dropdown";
import Background from "../../assets/backgroud.svg"

import { useDispatch } from 'react-redux';
import { updateConsultation, getRequestTypes, register } from '../../store/modules/owner/actions';
import { useSelector } from 'react-redux';
import { useForm, Controller } from 'react-hook-form';
export default function ConsultationRequest({ children }) {
    const dispatch = useDispatch();
    const { register, handleSubmit, control, watch, setValue, formState: { errors } } = useForm();
    const [options, setOptions] = useState([])
    const state = useSelector(state => state?.owner)
    const [consultaiton, setConsultaiton] = useState({
        reqType: "",
        description: "",
    })
    useEffect(() => {
        dispatch(updateConsultation(consultaiton))
    }, [consultaiton])
    useEffect(() => {
        setOptions(
            state?.requestTypes?.map((val) => (
                { value: val?.name, name: val?.name, id: val?.id }
            ))
        )
        console.log(state, 'options')
    }, [state?.requestTypes])
    useEffect(() => {
        dispatch(getRequestTypes())
        console.log(options)
        setOptions(
            state?.requestTypes?.map((val) => (
                { value: val?.name, name: val?.name, id: val?.id }
            ))
        )
    }, [])
    const handleSaveRequest = () => {

        console.log(state?.consultation?.reqType)
        const data = {
            "userName": state?.ownerInfo?.firstName + state?.ownerInfo?.lastName,
            "name": state?.ownerInfo?.firstName,
            "surname": state?.ownerInfo?.lastName,
            "emailAddress": state?.ownerInfo?.email,
            "phoneNumber": state?.ownerInfo?.phoneNumber,
            "isActive": true,
            requestType: [
                state?.consultation?.reqType
            ],
            "issue": state?.consultaiton?.description,
            "houseCall": true,
            "dogs": [
                {
                    "name": state?.dogInfo?.dogName,
                    "breedId": state?.dogInfo?.breed,
                    "age": state?.dogInfo?.age,
                    "birthDate": state?.dogInfo?.birthDate,
                    "size": state?.dogInfo?.size,
                    "coatColor": state?.dogInfo?.coatColor,
                    "coatDensity": state?.dogInfo?.coatDensity,
                    "microchip": state?.dogInfo?.isMicrochip === 'on' ? true : false,
                    "sex": state?.dogInfo?.sex,
                    "isSpayedOrNeutered": state?.dogInfo?.isSpayedAndNeutered === 'on' ? true : false
                }
            ]
        }
        dispatch(register(data))
    }
    const onSubmit = (data) => {
        // dispatch(updateUserInfo(data));
        // dispatch(register(data));
    };
    return (
        <div className='bg-black flex justify-center items-center h-screen py-12'>
            <img src={Background} className='w-full h-full absolute' />

            <form className='lg:w-8/12 w-full bg-white shadow-md rounded-xl h-full relative pt-12' onSubmit={handleSubmit(onSubmit)}>
                <div className='flex items-center space-x-3 md:px-12 px-6 '>
                    <div className='w-16 h-16rounded-full'>
                        <img src={logo} />
                    </div>
                    <p className='font-bold text-xl uppercase'>
                        Tamers
                    </p>
                </div>
                <div className='py-8 md:mx-12 mx-6'>
                    <div className='w-full bg-gray-100 rounded-full overflow-hidden'>
                        <div className='w-5/12 bg-black py-1 '>

                        </div>
                    </div>
                </div>
                <h1 className='text-xl font-bold pt-3 md:px-12 px-6 '>
                    Describe your issue.
                </h1>
                <p className='text-gray-400 text-sm pb-4 md:px-12 px-6' >
                    Be general or as specific as you want. We will use the case's issue to match you with the best trainer that fits your needs.</p>
                <div className='pt-5 space-y-1 md:px-12 px-6 '>

                    <div>
                        <p className='pb-1'>
                            Issue
                        </p>
                        <textarea className='w-full border border-gray-400 py-1 px-2 rounded-md focus:outline-none' rows={5} onChange={(e) => {
                            setConsultaiton({
                                reqType: consultaiton.reqType,
                                description: e.target.value,
                            })
                        }} />
                    </div>
                </div>
                <div className='flex items-center justify-between pt-5 absolute bottom-10 w-full md:px-12 px-6'>
                    <div className=''>
                        <Link to='/owner/step-four'>
                            <div className='flex items-center space-x-3'>
                                <img src={Back} alt='back' className='w-6' />
                                <p className='text-sm cursor-pointer'>
                                    Back
                                </p>
                            </div>
                        </Link>
                    </div>
                    <div className='flex items-center justify-end'>
                        <div className=' flex justify-end items-center space-x-3'>
                            <Link to="/owner/step-four">
                                <p className='text-sm cursor-pointer'>
                                    Skip
                                </p>
                            </Link>
                            <Link to="/owner/is-house-calls">
                                <button className='bg-black text-white rounded-lg px-3 py-1' type="submit">
                                    Next
                                </button>
                            </Link>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    )
}
