import StoreCreate from "../pages/storeCreate/index"
import StepTwo from "../pages/storeCreate/stepTwo";
import StepThree from "../pages/storeCreate/stepThree";
import StepFour from "../pages/storeCreate/stepFour";
import StepFive from "../pages/storeCreate/stepFive";
import StepSix from "../pages/storeCreate/stepSix"
import IsHouseCalls from "../pages/storeCreate/isHouseCalls";
import HouseCall from "../pages/storeCreate/houseCall";
import SignUp from "../pages/storeCreate/signup";
import License from "../pages/storeCreate/license";
import AddLicense from "../pages/storeCreate/addLicense";
import Preferd from "../pages/storeCreate/preferdTraining"
import Main from "../pages/index"
import StepOneOwner from "../pages/customer/index"
import StepTwoOwner from "../pages/customer/stepTwo"
import StepThreeOwner from "../pages/customer/stepThree"
import StepFourOwner from "../pages/customer/stepFour"
import HouseCallOwner from "../pages/customer/houseCall"
import IsHouseCallOwner from "../pages/customer/isHouseCalls"
import ConsultationRequest from "../pages/customer/consultationRequest";
import SignupOwner from "../pages/customer/signup"
import AddInfo from '../pages/storeCreate/addInfo'
import Schdule from "../pages/storeCreate/shedule"
const routes = [
  {
    path: "/",
    element: <Main />,
  },
  {
    path: "/add-info",
    element: <AddInfo />,
  },
  {
    path: "/license",
    element: <License />,
  },
  {
    path: "/add-license",
    element: <AddLicense />,
  },
  {
    path: "/preferd-training-types",
    element: <Preferd />,
  },
  {
    path: "/step-one",
    element: <StoreCreate />,
  },
  {
    path: "/step-two",
    element: <StepTwo />,
  },
  {
    path: "/step-three",
    element: <StepThree />,
  },
  {
    path: "/step-four",
    element: <StepFour />,
  },
  {
    path: "/step-five",
    element: <StepFive />,
  },
  {
    path: '/schdule',
    element: <Schdule />,
  },
  {
    path: "/step-six",
    element: <StepSix />
  },
  {
    path: "/is-house-calls",
    element: <IsHouseCalls />
  },
  {
    path: "/house-call",
    element: <HouseCall />
  },
  {
    path: "/sign-up",
    element: <SignUp />,
  },
  {
    path: "/owner",
    element: <StepOneOwner />,
  },

  {
    path: "/owner/step-two",
    element: <StepTwoOwner />,
  },
  {
    path: "/owner/step-three",
    element: <StepThreeOwner />,
  },
  {
    path: "/owner/step-four",
    element: <StepFourOwner />,
  },
  {
    path: "/owner/is-house-calls",
    element: <IsHouseCallOwner />
  },
  {
    path: "/owner/house-call",
    element: <HouseCallOwner />
  },
  {
    path: "/owner/sign-up",
    element: <SignupOwner />
  },
  {
    path: "/owner/consultation-request",
    element: <ConsultationRequest />,
  }

]
export default routes;