import React, { useEffect, useState } from 'react'
import Card from '../../components/card';
import { Link } from 'react-router-dom';
import Back from "../../assets/back.svg"
import { useDispatch } from 'react-redux';
import { updateDogInfo, getBreeds } from '../../store/modules/owner/actions';
import { useSelector } from 'react-redux';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import logo from '../../assets/logo.svg'
import Background from "../../assets/backgroud.svg"

export default function StepTwo({ children }) {
    const dispatch = useDispatch();
    const state = useSelector(state => state?.owner)
    const [dogData, setDogData] = useState({
        dogName: null,
        breed: null,
        age: null,
        birthdate: null,
        size: null,
        sex: null,
        coatColor: null,
        coatDensity: null,
        isSpayedAndNeutered: false,
        isMicrochip: false
    })
    useEffect(() => {
        dispatch(updateDogInfo(dogData))
    }, [dogData])
    useEffect(() => {
        dispatch(getBreeds())
        console.log("state", state)
    }, [])
    const handleSaveLevel = () => {

        console.log('data', state)
    }
    return (
        <div className='bg-black flex justify-center items-center h-screen md:py-12 py-0 '>
            <img src={Background} className='w-full h-full absolute' />

            <div className='lg:w-4/12 md:w-1/2 w-full bg-white shadow-md md:rounded-xl rounded-none md:p-12 p-4  relative h-[700px] overflow-auto'>
                <div className='flex items-center space-x-3'>
                    <div className='w-16 h-16 rounded-full'>
                        <img src={logo} />
                    </div>
                    <p className='font-bold text-xl upppercase'>
                        Tamers
                    </p>
                </div>

        <div className='py-8'>
          <div className='w-full bg-gray-100 rounded-full overflow-hidden'>
            <div className='w-3/12 bg-black py-1 '>

            </div>
          </div>
        </div>
                <h1 className='text-xl font-bold pt-3'>
                    Add Dog Details

                </h1>
                <form className='pt-5 space-y-1'>
                    <div>
                        <p className='pb-1'>
                            Dog name
                        </p>
                        <input className='w-full border border-gray-400 py-3 px-2 rounded-md focus:outline-none' onChange={(e) => {
                            setDogData({
                                ...dogData,
                                dogName: e.target.value
                            })
                        }} />
                    </div>
                    <div>
                        <p className='pb-1'>
                            Breed
                        </p>
                        <select className='w-full border border-gray-400 py-3 px-2 rounded-md focus:outline-none' onChange={(e) => {
                            setDogData({
                                ...dogData,
                                breed: e.target.value
                            })
                        }} >
                            {state?.breedTypes?.map((val) => (
                                <option value={val?.id}>
                                    {val?.name}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div>
                        <p className='pb-1'>
                            Age
                        </p>
                        <input className='w-full border border-gray-400 py-3 px-2 rounded-md focus:outline-none' type='number' min="1" onChange={(e) => {
                            setDogData({
                                ...dogData,
                                age: e.target.value
                            })
                        }} />
                    </div>
                    <div>
                        <p className='pb-1'>
                            Birth date
                        </p>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker className='w-full border border-gray-400 px-2 rounded-md focus:outline-none' onChange={(e) => {
                                setDogData({
                                    ...dogData,
                                    birthdate: e
                                })
                            }} />
                        </LocalizationProvider>

                    </div>
                    <div className='flex items-center space-x-3'>
                        <div className='w-full'>
                            <p className='pb-1'>
                                Size
                            </p>
                            <select className='w-full border border-gray-400 py-3 px-2 rounded-md focus:outline-none' onChange={(e) => {
                                setDogData({
                                    ...dogData,
                                    size: e.target.value
                                })
                            }} >
                                <option value={0}>
                                    Mini
                                </option>
                                <option value={1}>
                                    Small
                                </option>
                                <option value={2}>

                                    Medium
                                </option>
                                <option value={3}>
                                    Large
                                </option>
                            </select>
                        </div>
                        <div className='w-full'>
                            <p className='pb-1'>
                                Sex
                            </p>
                            <select className='w-full border border-gray-400 py-3 px-2 rounded-md focus:outline-none' onChange={(e) => {
                                setDogData({
                                    ...dogData,
                                    sex: e.target.value
                                })
                            }}>
                                <option value={0}>
                                    Male
                                </option>
                                <option value={1}>
                                    Female
                                </option>
                            </select>
                        </div>
                    </div>
                    <div>
                        <p className='pb-1'>
                            Coat color
                        </p>
                        <select className='w-full border border-gray-400 py-3 px-2 rounded-md focus:outline-none' onChange={(e) => {
                            setDogData({
                                ...dogData,
                                coatColor: e.target.value
                            })
                        }}>
                            <option value={0}>
                                Brown
                            </option >
                            <option value={1}>
                                Black
                            </option>
                            <option value={2}>
                                White
                            </option>
                            <option value={3}>
                                Grey
                            </option>
                        </select>
                    </div>
                    <div>
                        <p className='pb-1'>
                            Coat density
                        </p>
                        <select className='w-full border border-gray-400 py-3 px-2 rounded-md focus:outline-none' onChange={(e) => {
                            setDogData({
                                ...dogData,
                                coatDensity: e.target.value
                            })
                        }} >
                            <option value={0}>
                                Extra thin
                            </option>
                            <option value={1}>
                                Thin
                            </option>
                            <option value={2}>
                                Thick
                            </option>
                            <option value={3}>
                                Extra thick
                            </option>
                        </select>
                    </div>
                    {/* <div className='flex space-x-3 pt-3'>
                        <div className='flex items-center space-x-2'>
                            <input type='checkbox'
                                checked={dogData?.isSpayedAndNeutered}
                                onChange={(e) => {
                                    setDogData({
                                        ...dogData,
                                        isSpayedAndNeutered: e.target.value
                                        
                                    })
                                }} />
                            <p className='text-sm text-gray-400 font-light'>
                                Spayed and neutered
                            </p>
                        </div>
                        <div className='flex items-center space-x-2'>
                            <input type='checkbox' checked={dogData?.isMicrochip}
                                onChange={(e) => {
                                    setDogData({
                                        ...dogData,
                                        isMicrochip: e.target.value
                                    })
                                }} />
                            <p className='text-sm text-gray-400 font-light'>
                                Microchip
                            </p>
                        </div>
                    </div> */}
                </form>
                <div className='flex items-center justify-between  pt-5'>
                    <Link to='/owner/step-two'>
                        <div className='flex items-center space-x-3'>
                            <img src={Back} alt='back' className='w-6' />
                            <p className='text-sm cursor-pointer'>
                                Back
                            </p>
                        </div>
                    </Link>
                    <div className='flex items-center justify-end'>
                        <div className=' flex justify-end items-center space-x-3'>
                            <Link to="/owner/step-four">
                                <p className='text-sm cursor-pointer'>
                                    Skip
                                </p>
                            </Link>
                            <Link to="/owner/step-four">
                                <button className='bg-black text-white rounded-lg px-3 py-1' onClick={handleSaveLevel}>
                                    Next
                                </button>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
