
import React, { useEffect, useState } from 'react'
import Card from '../../components/card';
import { Link, useNavigate } from 'react-router-dom';
import Back from "../../assets/back.svg"
import logo from '../../assets/logo.svg'
import Background from "../../assets/backgroud.svg"

import { useDispatch } from 'react-redux';
import { updateHourlyRate } from '../../store/modules/auth/action';
import { useForm, Controller } from 'react-hook-form';
import { useSelector } from 'react-redux';
export default function StepFour({ children }) {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { register, handleSubmit, control, setValue, formState: { errors } } = useForm();
    const state = useSelector(state => state.auth)
    const onSubmit = (data) => {
        console.log(data)
        dispatch(updateHourlyRate(data?.hourlyRate))
        navigate("/step-three");
        // dispatch(updateUserInfo(data));
        // dispatch(register(data));
    };
    useEffect(() => { }, [
        console.log("state", state)
    ])
    return (
        <div className='bg-black flex justify-center items-center h-screen py-12'>
            <img src={Background} className='w-full h-full absolute' />

            <form
                onSubmit={handleSubmit(onSubmit)}
                className="md:w-4/12 w-full bg-white shadow-md rounded-xl p-12 relative"
            >
                <div className='flex items-center space-x-3'>
                    <div className='w-16 h-16rounded-full'>
                        <img src={logo} />
                    </div>
                    <p className='font-bold text-xl uppercase'>
                        Tamers
                    </p>
                </div>

                <div className='py-8'>
                    <div className='w-full bg-gray-100 rounded-full overflow-hidden'>
                        <div className='w-7/12 bg-black py-1 '>

                        </div>
                    </div>
                </div>
                <h1 className='text-xl font-bold pt-3'>
                    Whats your preferred hourly rate?

                </h1>
                <p className='text-gray-400 text-sm'>
                    Tamers recommends to increase your rate by 20% if utilizing our pet data tracking features.
                </p>
                <div className='pt-5'>
                    <div>
                        <p className='pb-1'>
                            Hourly Rate
                        </p>
                        <input
                            type='number'
                            {...register("hourlyRate", { required: true, maxLength: 20 })}
                            className="w-full border border-gray-400 py-1 px-2 rounded-md focus:outline-none"
                        />
                        {errors.hourlyRate && <p className='text-xs text-red-500'>Horuly rate is required</p>}

                    </div>

                    <div className='flex items-center justify-between pt-5'>
                        <div className=''>
                            <Link to='/step-one'>
                                <div className='flex items-center space-x-3'>
                                    <img src={Back} alt='back' className='w-6' />
                                    <p className='text-sm cursor-pointer'>
                                        Back
                                    </p>
                                </div>
                            </Link>
                        </div>
                        <div className=' flex justify-end items-center space-x-3'>
                            <Link to="/schdule">
                                <p className='text-sm cursor-pointer'>
                                    Skip all
                                </p>
                            </Link>

                            <Link to="/step-three">
                                <p className='text-sm cursor-pointer'>
                                    Skip
                                </p>
                            </Link>
                            <Link to="/step-three">
                                <button className='bg-black text-white rounded-lg px-3 py-1' type='submit'>
                                    Next
                                </button>
                            </Link>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    )
}
