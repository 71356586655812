
import React, { useEffect, useState } from 'react'
import Card from '../../components/card';
import { Link } from 'react-router-dom';
import Back from "../../assets/back.svg"
import ClientFinder from "../../assets/features/clientFinder.svg"
import Ecomerce from "../../assets/features/ecomerce.svg"
import TamersService from "../../assets/features/tamersService.svg"
import logo from '../../assets/logo.svg'
import Background from "../../assets/backgroud.svg"

import { useDispatch } from 'react-redux';
import { updateFeatures } from '../../store/modules/auth/action';
import { useSelector } from 'react-redux';
export default function StepThree({ children }) {
    const dispatch = useDispatch();
    const state = useSelector(state => state)
    const [features, setFeatures] = useState({
        ecomerce: false,
        clientFinder: false,
        tamersService: false,
    });

    const handleSavefeatures = () => {
        dispatch(updateFeatures({
            ecomerce: features?.ecomerce ? 'ecommerce' : '',
            clientFinder: features?.clientFinder ? 'services' : '',
            tamersService: features?.tamersService ? 'client finder' : ''
        }));
    }
    const handleCheckboxChange = (option) => {
        console.log('', option)
        setFeatures((prevValues) => ({
            ...prevValues,
            [option]: !prevValues[option],
        }));
    };
    useEffect(() => { }, [
        console.log("state", state)
    ])
    return (
        <div className='bg-black flex justify-center items-center h-screen py-12'>
            <img src={Background} className='w-full h-full absolute' />

            <div className='lg:w-8/12 w-full bg-white shadow-md rounded-xl p-12 h-full relative'>
                <div className='flex items-center space-x-3'>
                    <div className='w-16 h-16rounded-full'>
                        <img src={logo} />
                    </div>
                    <p className='font-bold text-xl uppercase'>
                        Tamers
                    </p>
                </div>
                <div className='py-8'>
                    <div className='w-full bg-gray-100 rounded-full overflow-hidden'>
                        <div className='w-5/12 bg-black py-1 '>

                        </div>
                    </div>
                </div>
                <h1 className='text-xl font-bold'>
                    What features would you like to use?

                </h1>
                <p className='text-gray-400'>
                    Pick as many as you like - you can always change these later. We’ll make sure your business is set up for these features.  </p>

                <div className='pt-5 grid md:grid-cols-2 grid-cols-1 gap-5'>
                    <Card title={'E-commerce'} icon={Ecomerce} value={'ecommerce'} selected={features?.ecomerce ? 'ecommerce' : ''} handleClick={() => handleCheckboxChange('ecomerce')} type={'checkbox'} />
                    <Card title={'Services'} icon={TamersService} value={'services'} selected={features?.tamersService ? 'services' : ''} handleClick={() => handleCheckboxChange('tamersService')} type={'checkbox'} />
                    <Card title={'Client finder'} icon={ClientFinder} value={'client finder'} selected={features?.clientFinder ? 'client finder' : ''} handleClick={() => handleCheckboxChange('clientFinder')} type={'checkbox'} />
                </div>
                <div className='absolute bottom-10 left-10'>
                    <Link to='/license'>
                        <div className='flex items-center space-x-3'>
                            <img src={Back} alt='back' className='w-6' />
                            <p className='text-sm cursor-pointer'>
                                Back
                            </p>
                        </div>
                    </Link>
                </div>
                <div className='absolute bottom-10 right-10 flex justify-end items-center space-x-3'>

                    <Link to={features?.clientFinder ? "/step-two" : '/sign-up'}>
                        <button className='bg-black text-white rounded-lg px-3 py-1' onClick={handleSavefeatures}>
                            Next
                        </button>
                    </Link>
                </div>
            </div>
        </div>
    )
}
