import React, { useEffect, useState } from 'react'
import Card from '../../components/card';
import Back from "../../assets/back.svg"
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { updateOwnerInfo } from '../../store/modules/owner/actions';
import { useSelector } from 'react-redux';
import logo from '../../assets/logo.svg'

import Background from "../../assets/backgroud.svg"
import { useForm, Controller } from 'react-hook-form';
export default function StepOne({ children }) {
    const { register, handleSubmit, control, watch, setValue, formState: { errors } } = useForm();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const state = useSelector(state => state)
    const [userData, setUserData] = useState({
        firstName: "",
        lastName: "",
        email: '',
        password: '',
        phoneNumber: ''
    })
    useEffect(() => {
        dispatch(updateOwnerInfo(userData))
    }, [userData])
    const handleSaveInfo = () => {
        console.log('data', state)
    }
    const onSubmit = (data) => {
        navigate("/owner/sign-up");
        // dispatch(updateUserInfo(data));
        // dispatch(register(data));
    };
    return (
        <div className='bg-black flex justify-center items-center h-screen py-12'>
            <img src={Background} className='w-full h-full absolute' />
            <div className='lg:w-4/12 md:w-1/2 w-full bg-white shadow-md rounded-xl md:p-12 p-6 relative'>
                <div className='flex items-center space-x-3'>
                    <div className='w-16 h-16rounded-full'>
                        <img src={logo} />
                    </div>
                    <p className='font-bold text-xl uppercase'>
                        Tamers
                    </p>
                </div>

                <div className='py-8'>
                    <div className='w-full bg-gray-100 rounded-full overflow-hidden'>
                        <div className='w-1/12 bg-black py-1 '>

                        </div>
                    </div>
                </div>
                <h1 className='text-xl font-bold pt-3'>
                    Create a Tamers account

                </h1>

                <form className='pt-5' onSubmit={handleSubmit(onSubmit)}>
                    <div>
                        <p className='pb-1'>
                            First name
                        </p>
                        <input
                            {...register("firstName", { required: true, maxLength: 20 })}
                            className="w-full border border-gray-400 py-1 px-2 rounded-md focus:outline-none"
                        />
                        {errors.firstName && <p className='text-xs text-red-500'>First Name is required</p>}

                    </div>
                    <div>
                        <p className='pb-1'>
                            Last name
                        </p>
                        <input
                            {...register("lastName", { required: true, maxLength: 20 })}
                            className="w-full border border-gray-400 py-1 px-2 rounded-md focus:outline-none"
                        />
                        {errors.lastName && <p className='text-xs text-red-500'>Last Name is required</p>}

                    </div>
                    {/* <div>
                        <p className='pb-1'>
                            Email address
                        </p>
                        <input
                            type="email"
                            {...register("email",
                                {
                                    required: true,
                                    pattern: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                                })}
                            className="w-full border border-gray-400 py-1 px-2 rounded-md focus:outline-none"
                        />
                        {errors.email && <p className='text-xs text-red-500'>Email is required</p>}

                    </div> */}
                    <div>
                        <p className='pb-1'>
                            Phone Number
                        </p>
                        <input
                            {...register("phoneNumber", { required: true })}
                            className="w-full border border-gray-400 py-1 px-2 rounded-md focus:outline-none"
                        />
                        {errors.phoneNumber && <p className='text-xs text-red-500'>Phone Number is required</p>}

                    </div>
                    {/* <div>
                        <p className='pb-1'>
                            Password
                        </p>
                        <input
                            type="password"
                            {...register("password", {
                                required: true
                            })}
                            className="w-full border border-gray-400 py-1 px-2 rounded-md focus:outline-none"
                        />
                        {errors.password && <p className='text-xs text-red-500'>Password is required</p>}

                    </div> */}

                    <div className='flex items-center justify-between pt-5'>
                        <Link to='/'>
                            <div className='flex items-center space-x-3'>
                                <img src={Back} alt='back' className='w-6' />
                                <p className='text-sm cursor-pointer'>
                                    Back
                                </p>
                            </div>
                        </Link>
                        <div className=' flex justify-end items-center space-x-3'>
                            {/* <Link to="/owner/step-two"> */}
                            <button className='bg-black text-white rounded-lg px-3 py-1' type="submit">
                                Next
                            </button>
                            {/* </Link> */}
                        </div>
                    </div>
                </form>
            </div>
        </div>
    )
}
